<template>
  <div class="LogOut">
    <div hide hidden>LOGOUT</div>
    <Loading />
  </div>
</template>

<script>
export default {
  components: {
    Loading: () => import("@/common/Loading.vue"),
  },
  computed: {
    payload: function() {
      return {
        _id: this.$route.query.user,
        SSID: this.$route.query.sstk,
      };
    },
  },
  methods: {
    async logOutNow() {
      await this.$store.dispatch("user/logoutMethod", this.payload);
    },
  },
  async mounted() {
    await this.logOutNow();
  },
};
</script>
